<template>
  <div>
    <div class="title">{{ $t("adnavPage.repaymentManage") }}</div>
    <el-form class="flex-row" ref="form" :model="form" style="width: 100%;">
      <el-form-item class="flex-row project-view" :label="$t('formTitleI18n.ChooseBrandOwner')">
        <el-select v-model="form.brandOwner" placeholder="" @change="changeBo" clearable>
          <el-option
              v-for="(item, index) in brandOwnerList"
              :key="index"
              :label="item.username"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="search-date" :label="$t('formTitleI18n.ChooseSupplier')">
        <el-select v-model="form.supplier" placeholder="" @change="changeSp" clearable>
          <el-option
              v-for="(item, index) in supplierList"
              :key="index"
              :label="item.username"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="btn-list" style="margin-bottom: 20px">
      <el-button
        v-for="(item, index) in btnList"
        :class="item.select ? 'btn-black' : 'btn-gray'"
        :key="index"
        @click="typeClick(item, index)"
        >{{ $t(item.title) }}</el-button
      >
    </div>
    <el-table :data="tableData" style="width: 100%">
      <template slot="empty">
        <div v-loading="tableLoading" v-text="tableEmpty"></div>
      </template>
      <el-table-column
        prop="user.username"
        :show-overflow-tooltip="true"
        :label="$t('tableI18n.BorrowerSupplier')"
        width="110"
        align="center"
        key="1"
      >
      </el-table-column>
      <el-table-column
        prop="boUser.username"
        :show-overflow-tooltip="true"
        :label="$t('tableI18n.BrandOwner')"
        width="110"
        align="center"
        key="2"
      >
        <template v-slot="scope">
          <div v-if="scope.row.boUser">{{scope.row.boUser.username}}</div>
          <div v-else>{{ $t('experienceInSupplyChainInvestmentRadio.none') }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="id"
        :show-overflow-tooltip="true"
        :label="$t('tableI18n.ProjectNumber')"
        width="80"
        align="center"
        key="3"
      ></el-table-column>
<!--      <el-table-column
        prop="financingType"
        :label="$t('tableI18n.FinancingType')"
        width="95"
        align="center"
        key="4"
      >
      </el-table-column>-->
      <el-table-column
        prop="financingAmount"
        width="110"
        align="center"
        key="5"
      >
        <template slot="header">
          {{ $t('tableI18n.FinancingAmount') }}<br/>{{ $t('unitI18n.MillionUsDollars') }}
        </template>
        <template v-slot="scope">
          {{ ((Number(scope.row.financingAmount || 0) / $enums.UNIT_MILLION).toFixed(2) || 0) | toThousandFilterTwo }}
        </template>
      </el-table-column>
      <el-table-column
        prop="receivablesOrder.contractNumber"
        :label="$t('tableI18n.ContractReferenceNumber')"
        width="110"
        align="center"
        key="6"
      ></el-table-column>
      <el-table-column
        prop="promisedPaymentDate"
        :label="$t('tableI18n.DeadlineForRepayment')"
        width="110"
        align="center"
        key="7"
      ></el-table-column>
      <el-table-column
        prop=""
        width="110"
        align="center"
        key="8"
      >
        <template slot="header">
          {{ $t('tableI18n.Interest') }}<br/>{{ $t('unitI18n.MillionUsDollars') }}
        </template>
        <template v-slot="scope">
          {{ (((Number(scope.row.orderAmount || 0) - Number(scope.row.financingAmount || 0)) / $enums.UNIT_MILLION).toFixed(2) || 0) | toThousandFilterTwo }}
        </template>
      </el-table-column>
      <el-table-column
        prop="repayAmount"
        width="130"
        align="center"
        key="9"
      >
        <template slot="header">
          {{ $t('tableI18n.BrandOwner') }}<br/>{{ $t('tableI18n.RepaymentAmount') }}<br/>{{ $t('unitI18n.MillionUsDollars') }}
        </template>
        <template v-slot="scope">
          {{ ((Number(scope.row.orderAmount || 0) / $enums.UNIT_MILLION).toFixed(2) || 0) | toThousandFilterTwo }}
        </template>
      </el-table-column>
      <el-table-column
        prop="repayAmount"
        width="130"
        align="center"
        key="10"
      >
        <template slot="header">
          {{ $t('tableI18n.Investor') }}<br/>{{ $t('tableI18n.IncomeAmount') }}<br/>{{ $t('unitI18n.MillionUsDollars') }}
        </template>
        <template v-slot="scope">
          {{ ((Number(scope.row.orderAmount || 0) / $enums.UNIT_MILLION).toFixed(2) || 0) | toThousandFilterTwo }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="form.status !== 3"
        prop="repayTransationCode"
        :label="$t('tableI18n.BankTransactionCode')"
        align="center"
      >
      </el-table-column>
      <el-table-column
          v-if="form.status === 4"
          prop="repaymetAuditLog.adminUsername"
          :label="$t('tableI18n.Operator')"
          align="center"
      >
      </el-table-column>
      <el-table-column
          v-if="form.status === 4"
          prop="repaymetAuditLog.createdAt"
          :label="$t('tableI18n.OperatorTime')"
          align="center"
          width="200"
      >
        <template v-slot="scope">{{ scope.row.repaymetAuditLog && scope.row.repaymetAuditLog.createdAt }}</template>
      </el-table-column>
      <el-table-column
        fixed="right"
        :label="$t('tableI18n.Operation')"
        :width="form.status === 3 ? '200' : '100'"
        align="center"
      >
        <template v-slot="scope">
          <el-button
            v-show="btnList[0].select"
            size="small"
            class="btn-black"
            @click="paypopShow(scope.$index, scope.row)"
            >{{ $t("repayment") }}</el-button
          >
          <el-button
            size="small"
            class="btn-black"
            @click="toDetail(scope.$index, scope.row)"
            >{{ $t("see") }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <AdPagination :currentPage="tablePageCP" :pageSize="tablePagePS" :pageTotal="tableTotal" @handlePage="handlePage"></AdPagination>

    <el-dialog
        :close-on-click-modal="false"
        :title="$t('dialogTitleI18n.Repayment')"
        :visible.sync="payPop"
        width="50%">
      <div class="text-center flex-column-center">
        <div><el-image class="ct-img" :src="imagesSrc[0]"></el-image></div>
        <div class="text-left" v-show="language === 'zh-cn'">{{ $t("dialogCtI18n.Repay1") }}<el-link type="primary" :underline="false">{{ dialogObj.brandOwner }}</el-link>{{ $t("dialogCtI18n.Repay2") }}<el-link type="primary" :underline="false">{{ ((Number(dialogObj.amount || 0) / $enums.UNIT_MILLION).toFixed(2) || 0) | toThousandFilterTwo }} {{ $t("millionDollars") }}</el-link>，</div>
        <div class="text-left" v-show="language === 'zh-cn'">{{ $t("dialogCtI18n.Repay3") }}<el-link type="primary" :underline="false">{{ dialogObj.orderNumber }}</el-link>{{ $t("dialogCtI18n.Repay4") }}</div>
        <div class="text-left" v-show="language !== 'zh-cn'">{{ $t("dialogCtI18n.Repay1") }}</div>
        <div class="text-left" v-show="language !== 'zh-cn'">{{ $t("dialogCtI18n.Repay2") }}<el-link type="primary" :underline="false">{{ dialogObj.orderNumber }}</el-link>,</div>
        <div class="text-left" v-show="language !== 'zh-cn'">{{ $t("dialogCtI18n.Repay3") }}<el-link type="primary" :underline="false">{{ dialogObj.brandOwner }}</el-link>{{ $t("dialogCtI18n.Repay4") }}<el-link type="primary" :underline="false">{{ ((Number(dialogObj.amount || 0) / $enums.UNIT_MILLION).toFixed(2) || 0) | toThousandFilterTwo }} {{ $t("millionDollars") }}</el-link> :</div>
        <div class="text-left"><el-input style="width: 100%;" v-model="form.payNumber"></el-input></div>
        <div class="dialog-btn">
          <el-button class="btn-black" @click="repay" :disabled="isSubmit">{{ $t('btnI18n.Confirms') }}</el-button>
          <el-button class="btn-black" @click="payPop = false;" :disabled="isSubmit">{{ $t('btnI18n.Cancel') }}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { connect, getUsers } from '@/utils/blockchain';
import { apiGetFinanceRequests, apiGetMyBalanceBignum, apiGetMyBalance } from "@/utils/api";
import {differenceInDays} from "date-fns";
import BigNumber from "bignumber.js";
import AdPagination from "@/components/ad-pagination";

export default {
  components: {
    AdPagination
  },
  data() {
    return {
      imagesSrc: [require(`@/static/img/dialog-repay.png`)],
      isSubmit: false,
      form: {
        boUserNull: false,
        brandOwner: "",
        supplier: "",
        payNumber: '',
        // 3 还款 4 结清
        status: 3
      },
      brandOwnerList: [],
      supplierList: [],
      primaryData: [],
      tableData: [],
      tableLoading: false,
      tableEmpty: this.$t('loading'),
      btnList: [{ title: 'toBeRepaid', select: true }, { title: 'btnI18n.financRepaid', select: false }],
      payPop: false,
      repayId: null,
      // 弹窗对象
      dialogObj: {
        amount: '',
        brandOwner: '',
        orderNumber: ''
      },
      userAccountAmount: 0,
      tableTotal: 0,
      tablePageCP: 1,
      tablePagePS: 10,
    };
  },
  created() {
    this.initData();
    this.getBoList();
    this.getSpList();
  },
  methods: {
    handlePage(val) {
      this.tablePageCP = val.currentPage;
      this.tablePagePS = val.pageSize;
      this.initData();
    },
    typeClick(item, index) {
      this.btnList.forEach(item => {
        item.select = false;
      });
      this.btnList[index].select = true;
      if (index === 0) {
        this.form.status = 3;
      } else {
        this.form.status = 4;
      }
      this.form.boUserNull = false;
      this.form.brandOwner = "";
      this.form.supplier = "";
      this.initData();
    },
    changeBo(value) {
      this.form.brandOwner = value;
      this.form.boUserNull = value < 0;
      this.initData();
    },
    changeSp(value) {
      this.form.supplier = value;
      this.initData();
    },
    getBoList() {
      let _this = this;
      this.$axios.get("/manage-common/role-user", { params: {role: "3"} }).then(res => {
        if (!res.code) {
          _this.brandOwnerList = res.data;
          _this.brandOwnerList.unshift({
            id: -1,
            username: this.$t('experienceInSupplyChainInvestmentRadio.none'),
          });
        }
      });
    },
    getSpList() {
      let _this = this;
      this.$axios.get("/manage-common/role-user", { params: {role: "1"} }).then(res => {
        if (!res.code) {
          _this.supplierList = res.data;
        }
      });
    },
    initData() {
      this.tableLoading = true;
      let params = {
        boUserNull: this.form.boUserNull,
        boUserId: this.form.brandOwner,
        spUserId: this.form.supplier,
        status: this.form.status,
        page: this.tablePageCP,
        pageSize: this.tablePagePS
      };
      if (!this.form.brandOwner || this.form.boUserNull) delete params.boUserId;
      if (!this.form.supplier) delete params.spUserId;
      if (!this.form.boUserNull) delete params.boUserNull;
      let _this = this;
      this.$axios.get("/manage-repayment/orders", { params: params }).then(res => {
        this.tableLoading = false;
        if (!res.code) {
          _this.tableData = res.data.rows;
          _this.tableTotal = res.data.count || 0;
        }
        this.tableEmpty = this.$t('tableEmpty');
      });
    },
    toDetail(index, rows) {
      this.$router.push({path: "/repayment-list/repayment-detail", query: { id: rows.id }});
    },
    paypopShow(index, row) {
      this.form.payNumber = "";
      this.dialogObj = {
        amount: row.orderAmount,
        brandOwner: (row.boUser && row.boUser.username) || row.user.username,
        orderNumber: row.receivablesOrder.contractNumber
      };
      this.repayId = row.id;
      this.payPop = true;
      // this.repay();
    },
    repay() {
      this.isSubmit = true;
      let params = {
        repayTransationCode: this.form.payNumber,
        id: this.repayId,
      };
      if (!this.form.payNumber) {
        this.isSubmit = false;
        this.$message.error(this.$t("formPlaceHolder.PhContractNum"));
        return false;
      }
      let _this = this;
      this.$axios.post("/manage-repayment/repay", params).then(res => {
        _this.isSubmit = false;
        if (!res.code) _this.payPop = false;
        _this.initData();
      }).catch(err => {
        _this.isSubmit = false;
        console.log(err);
      });
    },
    formatStatus(financingType) {
      let t = '';
      switch (financingType) {
        case 'Funded':
          t = this.$t('repaymentInProgress');
          break;
        default:
          t = financingType;
      }
      return t;
    },
  },
  computed: {
    language() {
      return this.$store.getters['auth/language'];
    }
  },
  watch: {
    language() {
      console.log(this.language);
    }
  }
};
</script>

<style lang="scss" scoped>
.info-view {
  flex-wrap: wrap;
  .info-item {
    width: 24%;
    line-height: 30px;
  }
}
.el-table th {
  background: #81d8d0;
  color: white;
}
.el-form-item__label {
  margin-left: 20px;
}
.project-view {
  width: 450px;
}
.search-date {
  width: 450px;
  margin-left: 50px;
  .el-form-item,
  .el-form-item__label,
  .el-form-item__content {
    display: inline-block;
  }
  .el-input,
  .el-input__inner {
    width: 300px;
  }
}
.el-input,
.el-input__inner {
  width: 200px;
}
.pop-info {
  font-size: 18px;
  line-height: 26px;
  position: relative;
  color: #353535;
  width: 360px;
  margin: 0 auto;
  word-break: normal;
  .title-blue {
    width: 660px;
    height: 50px;
    background: #81d8d0;
    position: absolute;
    top: -80px;
    img {
      width: 30px;
      height: 30px;
      margin-right: 20px;
    }
  }
  .time-before {
    font-size: 22px;
    font-weight: bold;
    line-height: 50px;
  }
  .bank {
    font-size: 16px;
    line-height: 20px;
    // margin-left: -20px;
    margin: 14px 0px 30px -80px;
    span {
      width: 200px;
      display: inline-block;
      text-align: right;
    }
  }
  .account {
    text-align: center;
  }
  .btn {
    border-radius: 0;
    background: #353535;
    color: white;
    width: 130px;
  }
  .cancel {
    border-radius: 0;
    background: #fff;
    color: #353535;
    width: 130px;
  }
}
.text-center {
  text-align: center;
  .text-left {
    text-align: left;
    width: 60%;
    min-width: 480px;
    margin: 5px 0;
  }
  .ct-img {
    width: 96px;
    height: 96px;
    margin-bottom: 21px;
  }
  .dialog-btn {
    margin-top: 44px;
  }
}
</style>
